import axios from 'api/axios'
import imageCompression from 'browser-image-compression'

export const compressImage = async (file: File) => {
	const options = {
		maxSizeMB: 1,
		maxWidthOrHeight: 1920
	}
	try {
		return await imageCompression(file, options)
	} catch (error) {
		console.error('이미지 압축 실패:', error)
		return file
	}
}

export const uploadImageToServer = async (file: File) => {
	const formData = new FormData()
	formData.append('files', file)
	formData.append('placeKey', 'some_unique_key')

	try {
		const response = await axios.post('/image/one/upload', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})

		if (response.data.key && response.data.url) {
			return response.data.url
		} else {
			console.error('이미지 업로드 실패:', response.data)
			return null
		}
	} catch (error) {
		console.error('이미지 업로드 실패:', error)
		return null
	}
}
