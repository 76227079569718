/**
 * Axios Request Wrapper
 * ---------------------
 *
 * @author  Wonjun Hwang
 *
 */

import axios from 'axios'

/**
 * Create an Axios Client with defaults
 */

const adminApi = axios.create({
	baseURL: process.env.REACT_APP_STUDY_MOA_API_URL,
	headers: {
		'Access-Control-Allow-Origin': 'https://stg.studymoa.me, http://localhost:3000',
		access_token: localStorage.getItem('access_token') || '',
		Authorization: `Bearer ${localStorage.getItem('access_token')}` || '',
		'Content-type': 'application/json'
	}
})

// adminApi.interceptors.response.handlers = []
adminApi.interceptors.response.use(
	function (res: any) {
		const { data } = res
		if (data.result == 'fail' && data.message == '사용할 수 없는 계정입니다') {
			localStorage.removeItem('access_token')
			window.location.href = '/'
		}
		return res
	},
	function (err) {
		const { response = {} } = err
		const { data = {} } = response
		if (data.result == 'fail' && data.message == '사용할 수 없는 계정입니다') {
			localStorage.removeItem('access_token')
			console.log('call')
			window.location.href = '/'
		}
		return err
	}
)

export default adminApi
