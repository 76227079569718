import { withStyles } from '@material-ui/core'
import classNames from 'classnames'
import { alertMessage, YYYYMMDD } from 'common/utils'
import { isMobile } from 'react-device-detect'
import { useMemo, useState } from 'react'
import { BootstrapTable, TableHeaderColumn, Options } from 'react-bootstrap-table'
import { useQuery } from 'react-query'
import { Board } from 'types/Board'
import AddDialog from './AddDialog'
import InsertBtn from './AdminDialog/InsertBtn'
import { newsApi } from './utils/NewsApi'
import 'react-bootstrap-table/css/react-bootstrap-table.css'

const styles = () => ({
	tableLayout: {
		background: 'white',
		border: 'solid 2px #dddddd',
		padding: '20px',
		margin: '10px',
		borderRadius: '12px'
	},
	messageLayout: {
		background: 'white',
		border: 'solid 2px #dddddd',
		padding: '20px',
		margin: '10px',
		borderRadius: '12px',
		width: '320px'
	},
	messageTitle: {
		display: 'block',
		textAlign: 'left',
		marginBottom: '10px',
		fontSize: '20px',
		fontWeight: '600'
	},
	messageTxt: {
		resize: 'none',
		marginTop: '20px',
		width: '100%'
	},
	messageBtn: {
		width: '100%',
		marginTop: '20px'
	}
})

const AdminBoard = ({ classes }) => {
	const [open, setOpen] = useState<boolean>(false)
	const [selected, setSelected] = useState<Board | undefined>()
	const { data: newsList, refetch } = useQuery(['admin', 'news', 'all'], () => newsApi.getNewsList(), {
		cacheTime: Infinity,
		staleTime: Infinity,
		refetchInterval: false
	})
	const toggleOpen = (toggle: Board) => {
		setSelected(toggle)
		setOpen(true)
	}
	const options: Options = useMemo(
		() => ({
			defaultSortName: 'wdate',
			defaultSortOrder: 'desc',
			noDataText: '데이터 없음',
			onRowClick: toggleOpen,
			insertBtn: () => <InsertBtn onClick={() => setOpen(true)} />,
			clearSearch: true,
			sizePerPage: 10,
			paginationSize: 3,
			prePage: 'Prev', // Previous page button text
			nextPage: 'Next', // Next page button text
			firstPage: 'First', // First page button text
			lastPage: 'Last' // Last page button text,
		}),
		[toggleOpen, newsList]
	)
	const parsedStringFormat = (value: string) => {
		const maxLength = isMobile ? 17 : 40
		return value.length > maxLength ? value.substring(0, maxLength) + ' ...' : value
	}

	return (
		<div>
			<div className={classNames(classes.tableLayout, 'row', 'bg-transparent')}>
				<BootstrapTable
					bodyStyle={{ position: 'relative' }}
					pagination
					insertRow
					tableStyle={{ overflowX: 'scroll' }}
					data={newsList?.list as object[]}
					options={options}
					hover
					search
					tableBodyClass="dark:text-moaWhite"
					containerClass="dark:text-moaWhite"
					searchPlaceholder="제목으로 검색"
					trClassName={(row) => {
						if (!row.isPublished) {
							return 'aactive'
						}

						return 'deactivate'
					}}
				>
					<TableHeaderColumn dataField="seq" width="60px" isKey={true} dataAlign="center" dataSort>
						No
					</TableHeaderColumn>
					<TableHeaderColumn dataField="title" width="200px" dataAlign="center" dataFormat={parsedStringFormat}>
						제목
					</TableHeaderColumn>
					<TableHeaderColumn dataField="wdate" width="100px" dataAlign="center" dataFormat={YYYYMMDD} dataSort>
						작성일자
					</TableHeaderColumn>
					<TableHeaderColumn dataField="startDt" width="100px" dataAlign="center" dataFormat={YYYYMMDD}>
						시작일
					</TableHeaderColumn>
					<TableHeaderColumn dataField="endDt" width="100px" dataAlign="center" dataFormat={YYYYMMDD}>
						종료일
					</TableHeaderColumn>
				</BootstrapTable>
			</div>
			{open && (
				<AddDialog
					board={selected}
					open={open}
					onCancel={() => {
						setOpen(false)
						setSelected(undefined)
						refetch()
					}}
					onDelete={(seq) => {
						// newsApi.removeBoard(seq).then(() => {
						// 	setOpen(false)
						// 	alertMessage({ title: '알림', message: '삭제가 완료되었습니다.', type: 'success' })
						// 	setSelected(undefined)
						// 	refetch()
						// })
					}}
				/>
			)}
		</div>
	)
}

export default withStyles(styles as any)(AdminBoard)
