import React, { useEffect, useRef, useState } from 'react'
import { loadData, makePreview, postData } from './libs/utils'
import { Typography, TextField, Button, FormControlLabel, Radio, RadioGroup, Checkbox } from '@material-ui/core'
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import UploadIcon from './images/upload-signature.png'
import EditImage from './components/EditImage'
import { Controller, useForm } from 'react-hook-form'
import { PortalDashboardPageWrapper } from './EditInfo.styled'
import axios from '../../api/axios'
import TextInput from './components/TextInput'
import GuideInput from './components/GuideInput'
import moment from 'moment'

// import './editinfo.css'
import { useNavigate } from 'react-router-dom'
import CircleLoader from 'components/CircleLoader'
import CardWrapper from 'common/components/CardWrapper'
import imageCompression from 'browser-image-compression'

const today = moment().format('YYYY')

function EditInfo() {
	const uploadBtn = useRef()
	const [state, setState] = useState()
	const { register, handleSubmit, control } = useForm()
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState()
	const [minBirthYearFlag, setMinBirthYearFlag] = useState(false)
	const [minBirthYear, setMinBirthYear] = useState(null)
	const [minAge, setMinAge] = useState(null)

	const handleImageChange = async (e) => {
		const { files } = e.target
		if (state.imageUrls.length + files.length > 10) {
			Store.addNotification({
				title: '이미지 갯수 초과.',
				message: '최대 10개 까지 등록 가능합니다.',
				type: 'danger',
				insert: 'top',
				container: 'top-center',
				animationIn: ['animated', 'fadeIn'],
				animationOut: ['animated', 'fadeOut'],
				dismiss: { duration: 3000 },
				dismissable: { click: true }
			})
			return
		}
		try {
			const placeKey = state.key
			setIsLoading(true)
			const result = await Promise.all(
				Array.prototype.map.call(files, async (file) => {
					const options = {
						maxSizeMB: 2, // 허용하는 최대 사이즈 지정
						maxWidthOrHeight: 800, // 허용하는 최대 width, height 값 지정
						useWebWorker: true // webworker 사용 여부
					}
					const compressedFile = await imageCompression(file, options)
					const formData = new FormData()
					formData.append('files', compressedFile)
					formData.append('placeKey', placeKey)

					const { data } = await axios.post('/image/one/upload', formData)
					const { key, url, result } = data
					if (result == 'fail') {
						alert('이미지업로드에 실패했습니다.')
					}
					return { url }
				})
			)
			setIsLoading(false)
			// {
			// 	"key": "undefined_b8e5abfd-67ef-41a8-8da0-b17edd81d0e0",
			// 	"url": "https://studymoa-place.s3.ap-northeast-2.amazonaws.com/undefined_b8e5abfd-67ef-41a8-8da0-b17edd81d0e0"
			// }

			const [imageUrls, filesObj] = makePreview(files, state.imageUrls.length)
			setState((prev) => ({
				...prev,
				filesObj: [...prev.filesObj, ...filesObj],
				imageUrls: [...prev.imageUrls, ...result]
			}))
		} catch (error) {
			console.error(error)
			setIsLoading(false)
			alert(error)
			return
		}
	}

	const onValid = async (data) => {
		if (parseInt(state.entry) > 1 && !data.pwGate) {
			alertMessage('알림', '입구 비밀번호를 설정해주세요.', 'warning')
			return
		}
		const body = {
			seq: state.seq,
			key: state.key,
			managerSeq: state.managerSeq,
			managerId: state.managerId,
			managerNm: state.managerNm,
			managerEmail: state.managerEmail,
			minBirthYear: minBirthYearFlag ? minBirthYear : null,
			entry: {
				qrCode: parseInt(state.entry) === 1 ? false : true,
				password: parseInt(state.entry) === 2 ? false : true
			},
			passwords: {
				gate: data.pwGate,
				toilet: data.pwToilet,
				wifi: data.pwWifi
			},
			buyerInfo: data.buyerInfo,
			guides: data.guides,
			kakaoId: data.kakaoId,
			managerEmail: data.managerEmail,
			minBirthYearFlag: data.minBirthYearFlag,
			operatingTime: data.operatingTime,
			phone: data.phone
		}
		try {
			for (let i = 0; i < 10; i++) {
				if (state.imageUrls[i]) {
					body[`imgUrl${i + 1}`] = state.imageUrls[i].url
				} else {
					body[`imgUrl${i + 1}`] = null
				}
			}
			const res = await postData(body.seq, body)
			if (res) {
				alertMessage('완료', '저장되었습니다.', 'success')
				setTimeout(() => navigate('dashboard'), 1000)
			} else {
				throw new Error('서버에러')
			}
		} catch (error) {
			alertMessage('에러', String(error.message), 'danger')
		}
	}

	const alertMessage = (title, message, type) => {
		Store.addNotification({
			title: title,
			message: message,
			type: type,
			insert: 'top',
			container: 'top-center',
			animationIn: ['animated', 'fadeIn'],
			animationOut: ['animated', 'fadeOut'],
			dismiss: { duration: 3000 },
			dismissable: { click: true }
		})
	}

	useEffect(() => {
		loadData(setState, setMinAge)
	}, [])

	useEffect(() => {
		minAge !== null ? setMinBirthYearFlag(true) : setMinBirthYearFlag(false)
		minAge > 0 ? setMinBirthYear(parseInt(today) - (parseInt(minAge) - 1)) : setMinBirthYear(null)
	}, [minAge])

	const handleMinAgeChange = (e) => {
		const value = e.target.value
		const intValue = Math.trunc(value)

		if (value === '' || (Number.isInteger(intValue) && intValue >= 0 && intValue <= 99)) {
			if (value === '') setMinAge('')
			else setMinAge(intValue)
		}
	}

	return state ? (
		<PortalDashboardPageWrapper style={{ height: '100%' }} className="dark:text-moaWhite text-moaBlack">
			<ReactNotifications />
			<div className={'row relative'} style={{ height: '100%' }}>
				{isLoading ? (
					<div className="fixed top-0 left-0 bg-white opacity-80 z-10 w-full h-full">
						<CircleLoader />
					</div>
				) : null}
				<div className="flex flex-wrap gap-3 w-full">
					<CardWrapper className={'col-md-12 col-lg-4 editinfo_row p-4 rounded-md'}>
						<div>
							<div>
								<div className="flex items-center">
									<span className={'col-md-2'} style={{ display: 'inline-block', width: '86px' }}>
										공간명
									</span>
									<span
										className={'bg-[#1abc9c] text-white h-fit p-1 px-2 rounded-xl mr-4'}
										style={{ display: 'inline-block' }}
									>
										{state.placeType}
									</span>
									<TextField
										className={'col-md-8 '}
										margin="normal"
										InputProps={{ classes: { input: 'dark:text-moaWhite opacity-50' } }}
										value={state.name}
										disabled
									/>
								</div>
								<TextInput
									title="운영시간"
									value={state.operatingTime}
									placeholder="ex) 오전 8시~오후 10시"
									control={control}
									name="operatingTime"
								/>
								<TextInput
									title="전화번호"
									placeholder="ex) 01012345678 "
									value={state.phone}
									control={control}
									name="phone"
								/>
								<TextInput
									title="관리메일"
									placeholder="ex) abc@gmail.com"
									value={state.managerEmail}
									control={control}
									name="managerEmail"
									text="email"
								/>
								<TextInput
									title="카톡채널"
									value={state.kakaoId}
									control={control}
									name="kakaoId"
									placeholder="주소 형식에서 채널 ID를 입력해주세요. ex) _KeGhp"
								/>
								<div className="flex flex-wrap mt-4 h-6">
									<span className="ml-4 mt-1 mb-1 w-20">출입구비번</span>
									<RadioGroup
										className={'mb-4 mt-[-10px]'}
										row={true}
										value={state.entry}
										onChange={(e) => {
											setState((prev) => ({
												...prev,
												entry: e.target.value
											}))
										}}
									>
										<FormControlLabel value={'1'} control={<Radio />} label="비밀번호" />
										<FormControlLabel value={'2'} control={<Radio />} label="QR" />
										<FormControlLabel value={'3'} control={<Radio />} label="비밀번호 + QR" />
									</RadioGroup>
								</div>

								<TextInput value={state.passwords.gate} placeholder="ex) 1234*" control={control} name="pwGate" />

								<TextInput
									title="화장실비번"
									placeholder="ex) 2층 위 1234*"
									value={state.passwords.toilet}
									control={control}
									name="pwToilet"
								/>
								<TextInput
									title="Wi-Fi"
									placeholder="ex) KT_A058 / 0000001"
									value={state.passwords.wifi}
									control={control}
									name="pwWifi"
								/>
								<div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
									<span style={{ marginLeft: '15px', width: '60px' }}>최소연령</span>
									<Controller
										name="minBirthYearFlag"
										control={control}
										defaultValue={minBirthYearFlag}
										render={({ field: { onChange } }) => (
											<Checkbox
												onChange={() => {
													onChange()
													if (minBirthYearFlag) {
														setMinAge('')
														setTimeout(() => {
															setMinBirthYearFlag(false)
														}, 10)
													} else {
														setMinBirthYearFlag(true)
													}
												}}
												checked={minBirthYearFlag}
											/>
										)}
									/>

									<TextField
										style={{ width: '100px', paddingRight: '20px' }}
										disabled={!minBirthYearFlag}
										type="number"
										placeholder="ex) 17"
										value={minAge}
										inputProps={{
											onWheel: (e) => e.target.blur(),
											step: '1',
											min: 0,
											max: 99
										}}
										onChange={handleMinAgeChange}
									/>
									<div>{minBirthYear ? `(${minBirthYear} 년생)` : null}</div>
								</div>
							</div>
							<GuideInput title="이용안내 및 주의사항" register={register('guides')} value={state.guides} />
						</div>
					</CardWrapper>

					<CardWrapper className={'col-md-12 col-lg-4 editinfo_row'}>
						<div className={'editinfo_div'}>
							<div className={'editinfo_div_top'}>
								<TextInput
									title="관리자"
									admin
									value={state.managerNm == null ? '' : state.managerNm + ' (' + state.managerNum + ')'}
									disabled
								/>
								<TextInput title="대표자명" admin value={state.ceoName == null ? '' : state.ceoName} disabled />
								<TextInput title="사업자등록번호" admin value={state.bizRegNum == null ? '' : state.bizRegNum} disabled />

								<TextInput title="사업자이메일" admin value={state.bizEmail == null ? '' : state.bizEmail} disabled />

								<TextInput
									title="주소"
									admin
									value={state.city + ' ' + state.state + ' ' + state.addr1 + ' ' + state.addr2}
									disabled
								/>
								<TextInput
									title="정산계좌"
									admin
									value={
										state.bankCode == null
											? ''
											: state.bankCode + ' ' + state.accountNum == null
											? ''
											: state.accountNum
									}
									disabled
								/>
							</div>
							<GuideInput title="구매자 안내사항" register={register('buyerInfo')} value={state.buyerInfo} />
						</div>
					</CardWrapper>

					<CardWrapper className={'col-md-12 col-lg-4 editinfo_row'}>
						<div className={'editinfo_div'}>
							<div style={{ verticalAlign: 'top' }}>
								<div style={{ position: 'relative' }}>
									<Typography
										variant="title"
										component="h2"
										gutterBottom
										classes={{ title: 'dark:text-moaWhite' }}
										style={{ paddingTop: '20px', marginLeft: '20px' }}
									>
										공간 이미지
										<p className={'text-sm'}>권장 이미지 크기: 800px</p>
									</Typography>
								</div>

								<input
									id="selectImages"
									className="form-control hidden_"
									type="file"
									accept="image/*"
									onChange={handleImageChange}
									ref={uploadBtn}
								/>

								<div style={{ position: 'relative', marginTop: '20px' }}>
									{state.imageUrls.map((data, i) => (
										<EditImage data={data} setState={setState} i={i} key={i} />
									))}
									{state.imageUrls.length < 10 ? (
										state.imageUrls.length > 0 ? (
											<div className="col-md-6 img_empty_div cursor-pointer">
												<img
													src={UploadIcon}
													className="img-rounded img-responsive img_empty"
													onClick={() => uploadBtn.current.click()}
												/>
												<br />
											</div>
										) : (
											<div className="col-md-12 img_empty_div_first">
												<img
													src={UploadIcon}
													className="img-rounded img-responsive img_empty_first"
													onClick={() => uploadBtn.current.click()}
												/>
												<br />
											</div>
										)
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</CardWrapper>
				</div>

				<div
					className={'col-md-12'}
					style={{ textAlign: 'center', verticalAlign: 'middle', padding: '20px', marginBottom: '20px' }}
				>
					<Button variant="outlined" size="large" color="primary" onClick={handleSubmit(onValid)}>
						저장
					</Button>
				</div>
			</div>
		</PortalDashboardPageWrapper>
	) : (
		<CircleLoader />
	)
}

export default EditInfo
