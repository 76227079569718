import { AlignType, Board } from 'types/Board'
import axios from 'api/axios'
import CONSTANTS from 'common/constant'
import axiosV1 from 'api/axiosV1'

export type BoardOrderBy = 'seq' | 'viewCount' | 'mdate'

interface MessageTitle {
	title: string
}

interface BoardReseponse<T = any> {
	success: boolean
	total_count: number
	total_page: number
	result?: T
}

interface ListResponse<T = any> extends BoardReseponse {
	list: T[]
}

async function getNewsList() {
	try {
		const res = await axios.get('/newsAdmin')
		return res.data
	} catch (e) {
		console.error(e)
	}
}

export const postNews = async (postData) => {
	return await axios.post('/news/create', postData).then((res) => res.data)
}

export const newsApi = { getNewsList, postNews }
